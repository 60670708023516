.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050; /* Ensure it overlays other content */
}

.modal-backdrop.show {
  opacity: 0.5; /* Optional: Adjust backdrop opacity */
}