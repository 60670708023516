.item-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.item-card {
  background-color: #f9f9f9;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.load-more-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.load-more-button:hover {
  background-color: #0056b3;
}
